import React from 'react'
import SEO from '../../components/seo'
import styles from '../../components/career.module.css'
import Layout from '../../containers/layout'
import { Helmet } from "react-helmet"


class Careers extends React.Component {

  constructor(props) {
    super(props);
  }
  // componentDidMount () {
    // const script = document.createElement("script");
    // script.src = "https://assets.livehire.com/scripts/widgets/job-listings-widget.host.1.0.4.js";
    // script.async = true;
    // document.body.appendChild(script);
  // }


  render() {
    return (
      <Layout noFooter={true}>
        <Helmet>
          <script
            src="https://assets.livehire.com/scripts/widgets/job-listings-widget.host.1.0.4.js"
            crossorigin="anonymous"
            async
          ></script>
        </Helmet>
        {/* <div className={styles.wrapper}> */}
          <SEO title='Careers' location={'Adelaide'} />

          <div className={`${styles.header} ${styles.headerAdelaide}`}>
            <span>
              Careers
            </span>
          </div>

          <div className={styles.frameWrap}>
            <iframe  
            className={'lh-job-listings-widget ' + styles.iframe}
            src="https://www.livehire.com/widgets/job-listings/tbwa-melbourne/public"
            scrolling="no" 
            ></iframe>
          </div>
      </Layout>
    )
  }
}

export default Careers
